.quiz {
  &__question {
    font-size: 1.5rem;
    margin-bottom: 24px;
  }

  &__option {

    &s {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media screen and (max-width: $b_phone) {
        flex-direction: column;
      }
    }

    padding: $s_sm;
    margin: $s_sm 0;
    font-size: 1.124rem;
    border: 1px solid $c_dispiriting;
    width: calc(50% - #{$s_sm * 3});
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    @media screen and (max-width: $b_phone) {
      width: auto;
    }
    
    &:nth-child(2n) {
      margin-right: 0;
    }
    &:nth-child(2n + 1) {
      margin-left: 0;
    }

    &--checked {
      color: $c_insidious;
      border-color: $c_insidious;
    }

    &--correct {
      background-color: $c_menacing;
      &:before {
        content: '✓';
        position: relative;
        margin-right: $s_sm;
        color: #C0C865;
      }
    }

    &--incorrect {
      border-color: red;
      &:before {
        content: '✕';
        position: relative;
        margin-right: $s_sm;
        color: red;
      }
    }


    input {
      opacity: 0;
      margin-left: -#{$s_sm};
    }
  }

  &__reference {
    font-size: 0.8rem;
    margin-top: $s_sm;
    margin-bottom: $s_sm;
    word-break: normal;
  }
}