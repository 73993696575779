.congrats {
  text-align: center;

  &-message {
    margin-top: $s_sm;
    font-size: 1.25rem;
    line-height: 2;
  }

  &-badge {
    width: 250px;
    margin: $s_lg auto;

    img {
      width: 100%;
    }
  }

  &-continue {
    .form-item {
      flex-direction: row;
      justify-content: center;

      button {
        margin: 0;
        min-width: 60px;
        &:first-child {
          margin-right: $s_md;
        }
      }
      
    }
  }

  &-share {
    margin-top: $s_md;
  }

}