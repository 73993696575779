@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Recursive:wght@400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: $f_base;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c_dispiriting;
}

a {
  color: $c_dispiriting;
  word-break: break-all;
}

h1,
h2,
h3 {
  font-family: $f_heading;
  text-align: center;
}

h2 {
  font-size: 2rem;
  line-height: 1.5rem;
}

h3 {
  font-size: 1.5rem;
}

h1.page-title {
  background: url(../assets/images/logo.png) no-repeat center center;
  background-size: contain;
  color: transparent;
  font-size: 3.5rem;
  letter-spacing: 1rem;
  margin: 2rem 0 7rem;
  text-align: center;
  position: relative;
}

@media (max-width: 1420px) {
  h1.page-title {
    margin: 4rem 0 2rem;
  }
}

button {
  background-color: $c_dispiriting;
  border: 0;
  color: $c_menacing;
  cursor: pointer;
  display: block;
  font-family: $f_base;
  font-size: 1rem;
  outline: 0;
  margin: 2rem auto 1rem;
  min-width: 120px;
  padding: 0.5rem 1rem;
  position: relative;
  text-transform: uppercase;
  transition: opacity 0.5s;
  z-index: 1;

  &:before,
  &:after {
    background-color: inherit;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }

  &:before {
    transform-origin: top left;
    transform: skewY(-2deg);
  }

  &:after {
    transform-origin: top right;
    transform: skewY(-2deg);
  }

  &:hover {
    opacity: 0.9;
  }

  &[disabled],
  &:disabled {
    cursor: auto;
    pointer-events: none;
    color: $c_disruptive;
    background-color: $c_insidious;
  }
}

.lead {
  font-size: 1.5rem;
  font-weight: bold;
}

.main-area {
  margin-left: 225px;
  position: relative;
  max-width: 100%;
}

@media (max-width: $b_tablet) {
  .main-area {
    margin-left: 0;
  }

  .page {
    top: 66px;
  }
  .page::before {
    top: 60px;
  }
}

.text-underlined{
  text-decoration: underline;
}