.footer {
  padding-left: 225px;

  .footer-wrap {
    width: 80%;
    max-width: 1080px;
    margin: auto;
    position: relative;
    padding: 60px 0 2% 0;
  }

  .terms-and-privacy {
    display: flex;
    justify-content: center;

    .terms {
      font-family: "Recursive", sans-serif;
      font-size: 1.25rem !important;
      font-weight: 600;
      color: #a0bac4;
      padding-bottom: 10px;
      line-height: 1em;
      text-decoration: none;
    }

    .divider {
      font-size: 25px;
      line-height: 20px;
      margin: 0 10px;
      color: #efede3;
      text-align: center;
    }
  }

  .social-links {
    margin: 20px 0 0;
    display: flex;
    justify-content: center;

    a {
      display: block;
      width: 40px;
      height: 40px;
    }

    .fb-link {
      background: url("https://cinrisk.com/wp-content/uploads/2020/11/facebook_Beige.svg") no-repeat center center;
    }

    .in-link {
      background: url("https://cinrisk.com/wp-content/uploads/2020/11/linkedin_Beige.svg") no-repeat center center;
      margin: 0 32px;
    }

    .twt-link {
      background: url("https://cinrisk.com/wp-content/uploads/2020/11/twitter_Beige.svg") no-repeat center center;
    }
  }

  .footer-logo-copy-rights {
    display: flex;
    direction: ltr;
    padding: 27px 0;

    .col-left {
      margin-right: 5.5%;
      width: 20.875%;
      img{
        width: 219px;
      }
    }

    .col-right {
      width: 73.62%;
    }

    .copy-rights-text {
      font-size: 12px;
      height: 80px;
      display: flex;
      align-items: center;
      color: #efede3;
      font-weight: 400;
    }
  }
}

@media (max-width: $b_tablet) {
  .footer {
    padding-left: 0;
    width: 100%;
    padding-bottom: 100px;
  }
}

@media (max-width: 1300px) {
  .footer {
    .col-left {
      width: 50%!important;
      img{
        max-width: 100%;
      }
    }

    .col-right {
      width: 50%!important;
    }
  }
}