.contacts-menu {
  right: 110px;
  position: fixed;
  top: 0;
  z-index: 100;
  background: #541718;
  border-radius: 0 0 20px 20px;

  .menu-container {
    width: 100%;
    max-width: 1080px;
    margin: auto;
  }

  .mobile-header {
    display: none;
  }

  .contacts-menu-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 20px;

    .divider {
      color: #EFEDE3;
      font-size: 18px;
      margin: 0 10px;
    }

    a {
      text-decoration: none;
      display: block;
      width: 100%;
      color: #EFEDE3;
      font-size: 14px;
      text-transform: uppercase;
    }
  }

}

@media (max-width: $b_tablet) {
  .contacts-menu {
    right: 0;
    border-radius: 0;
    width: 100%;
    height: 66px;

    .menu-container {
      width: 80%;
      height: 100%;
      position: relative;
    }

    .mobile-header {
      display: block;
      height: 100%;
      position: relative;

      img {
        height: 100%;
      }

      .mobile-menu-bar-toggle {
        position: absolute;
        top: 20px;
        cursor: pointer;
        right: 0;

        span {
          background: #fff;
          display: block;
          width: 21px;
          height: 2px;
          margin-bottom: 4px;
          border-radius: 2px;
        }
      }

    }

    .contacts-menu-inner {
      position: absolute;
      left: 0;
      padding: 5%;
      width: 90%;
      background: #fff;
      visibility: visible;
      opacity: 1;
      display: none;
      z-index: 9999;
      border-top: 3px solid #541718;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .1);

      .divider {
        display: none;
      }

      a {
        border-bottom: 1px solid rgba(0, 0, 0, .03);
        color: #666;
        width: 90%;
        padding: 10px 5%;
        display: block;
        font-size: 14px;
        font-weight: 600;
        line-height: 23px;
        text-transform: none;
        transition: all .2s ease-in-out;
      }

      a:hover {
        background-color: rgba(0, 0, 0, .03);
        opacity: .7;
      }
    }

    .contacts-menu-inner.visible {
      display: block;
    }
  }
}