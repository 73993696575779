.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 225px;
  height: 100%;
  background-color: #1d2a33;

  .logo_container {
    width: 100%;

    img {
      width: 100%;
      margin-bottom: 28px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      height: 70px;
      position: relative;
      padding-left: 5px;

      a {
        display: flex;
        align-items: center;
        color: #a0bac4;
        font-family: "Recursive", sans-serif;
        text-transform: uppercase;
        font-size: 16px;
        text-decoration: none;
        line-height: 1.2em;
        font-weight: 600;
        word-break: keep-all;
        padding-right: 20px;

        span,
        img {
          float: left;
        }

        span {
          margin-left: 10px;
        }

        &:hover {
          opacity: 0.7;
          transition: all 0.4s ease-in-out;
        }
      }
    }

    li:last-child {
      padding: 0;
      margin-top: 40px;
      background-color: #541718;

      a {
        padding: 15px 15px 0 25px;
        color: #efede3;

        span {
          width: 130px;
          margin-right: 10px;
        }
      }
    }
  }
}

@media (max-width: $b_tablet) {
  .main-menu {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 99;
    background-color: #541718;

    .logo_container {
      display: none;
    }

    ul {
      display: flex;

      li {
        flex: 1;
        text-align: center;
        padding: 9px 0;
        height: auto;
        list-style-type: none;
        border-right: 1px solid #354a59;
        background-color: #1d2a33;

        a {
          max-width: 100%;
          font-size: 0;
          padding: 0;
          display: block;

          span {
            display: none;
          }

          img {
            float: none;
            max-width: 70%;
          }
        }
      }

      li:last-child {
        display: none;
      }
    }
  }
}
