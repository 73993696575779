$f_base: 'Recursive', sans-serif;
$f_heading: 'Bebas Neue', cursive;


$c_menacing: #1D2A33;
$c_disruptive: #315D64;
$c_cruel: #541718;
$c_insidious: #A0BAC4;
$c_dispiriting: #EFEDE3;


$s_sm: 16px;
$s_md: 24px;
$s_lg: 32px;
$s_xlg: 48px;


$b_phone: 480px;
$b_tablet: 980px;
$s_tablet: 769px;