%card {
  background-color: $c_cruel;
  border: 1px solid $c_dispiriting;
  padding: $s_md;
  margin: $s_md;
}

.background-wrap{
  background: url(../assets/images/background.jpg) center center transparent;
  background-size: cover;
  min-height: 100vh;
}

.page {
  display: flex;
  justify-content: center;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
  }

  &:before {
    background: url(../assets/images/header.png) center bottom transparent no-repeat;
    background-size: cover;
    height:230px;
    top: 0;
  }

}

.container {
  max-width: 1200px;
}

main {
  display: flex;

  @media (max-width: $s_tablet)  {
    flex-direction: column;
  }
}

.content {
  @extend %card;
  flex: 1;
}

aside {
  @extend %card;
  width: 300px;

  @media screen and (max-width: $b_phone) {
    width: auto;
  }

  @media screen and (min-width: $s_tablet) and (max-width: 1100px)  {
    width: 200px;
  }

  @media (max-width: $s_tablet)  {
    width: auto;
  }
}

.references {
  @extend %card;
  max-height: 150px;
  overflow: auto;

  h2 {
    margin: 0;
  }

  p {
    font-size: 0.8rem;
  }
}

@media (max-width: 1420px) {
  .page {
    &:before {
      background: url(../assets/images/header_big.png) bottom transparent no-repeat;
      background-size: cover;
      height: 220px;
    }
  }
  main {
    display: flex;
    padding-top: 30px;
  }
  .footer .footer-wrap {
    width: 80%;
    max-width: 1080px;
    margin: 30px auto 0;
    position: relative;
    padding: 60px 0 0;
  }
}