.leaderboard {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    display: flex;
    line-height: 1.4em;

    &--footer {
      margin-top: $s_md;
    }

    &__name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__score {
      width: 50px;
      text-align: center;
    }
  }
}