.form {
  &-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: $b_phone) {
      flex-direction: column;
    }

    & .form-item {
      width: calc(50% - #{$s_sm});

      @media screen and (max-width: $b_phone) {
        width: 100%;
      }
    }

    & .form-item:nth-child(2n) {
      margin-right: 0;

      @media screen and (max-width: $b_phone) {
        margin: 1rem 0;
      }
    }
    & .form-item:nth-child(2n + 1) {
      margin-left: 0;

      @media screen and (max-width: $b_phone) {
        margin: 1rem 0;
      }
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    margin: $s_sm;

    label {
      margin-bottom: $s_sm;
    }

    input[type="text"],
    input[type="email"] {
      font-family: $f_base;
      font-size: 1.125rem;
      padding: $s_sm;
      color: $c_menacing;

      &:focus {
        outline: none;
      }
    }

    button {
      margin: 0 auto;
    }
  }

  &-error {
    color: red;
  }
}
